import { get } from 'lodash';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';

export const RELIABILITY_RATINGS_START_YEAR = 2008;
export const RELIABILITY_RATINGS_END_YEAR = 2022;

export function getRepairPalDataPath({ makeSlug, modelSlug, year }) {
  return (
    makeSlug &&
    modelSlug &&
    year &&
    year >= RELIABILITY_RATINGS_START_YEAR &&
    `makes["${makeSlug}"].models["${modelSlug}"].years["${Math.min(
      parseInt(year, 10),
      RELIABILITY_RATINGS_END_YEAR
    )}"].repairPal`
  );
}

export const ReliabilityEntities = {
  RepairPalRatings: PropTypes.shape({
    reliabilityScore5Scale: PropTypes.number,
    repairCost: PropTypes.number,
    relativeFrequency: PropTypes.number,
    severity: PropTypes.number,
    segment: PropTypes.string,
    segmentRankByReliabilityScore: PropTypes.number,
    modelCountOfSegment: PropTypes.number,
    avgSegmentRepairCost: PropTypes.number,
    avgSegmentRelativeFrequency: PropTypes.number,
    avgSegmentSeverity: PropTypes.number,
    gearScoreDescription: PropTypes.string,
  }),
};

export const ReliabilityModel = createModelSegment('reliability', [
  /**
   * @see getRepairPalDataPath
   * @returns {boolean}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].repairPal',
    async resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($makeSlug: String!, $modelSlug: String!, $year: Int!) {
              modelYears(makeSlug: $makeSlug, modelSlug: $modelSlug, year: $year) {
                repairPalData {
                  avgSegmentRelativeFrequency
                  avgSegmentRepairCost
                  avgSegmentSeverity
                  gearScoreDescription
                  modelCountOfSegment
                  relativeFrequency
                  reliabilityScore5Scale
                  repairCost
                  segment
                  severity
                  segmentRankByReliabilityScore
                }
              }
            }
          `,
          {
            makeSlug,
            modelSlug,
            year: parseInt(year, 10),
          }
        )
        .then(response => get(response, 'modelYears[0].repairPalData', null))
        .then(
          ratings =>
            ratings && {
              ...ratings,
              repairCost: ratings.repairCost && parseFloat(ratings.repairCost.toFixed(0)),
              avgSegmentRepairCost: ratings.avgSegmentRepairCost && parseFloat(ratings.avgSegmentRepairCost.toFixed(0)),
              avgSegmentRelativeFrequency:
                ratings.avgSegmentRelativeFrequency && parseFloat(ratings.avgSegmentRelativeFrequency.toFixed(2)),
              avgSegmentSeverity:
                ratings.avgSegmentSeverity && parseFloat((ratings.avgSegmentSeverity * 100).toFixed(1)),
              relativeFrequency: ratings.relativeFrequency && parseFloat(ratings.relativeFrequency.toFixed(2)),
              severity: ratings.severity && parseFloat((ratings.severity * 100).toFixed(1)),
            }
        )
        .catch(() => null);
    },
  },
]);
