import React, { Fragment } from 'react';
import { get, maxBy } from 'lodash';
import { getPriceString } from 'site-modules/shared/utils/price-utils';

export const getPricingLabel = (trim, isDisplayLabel) => {
  const msrp = trim?.pricing?.totalMsrp || trim?.pricing?.baseMsrp;
  const msrpText = isDisplayLabel ? (
    <Fragment>
      {' '}
      - <strong>{getPriceString(msrp)} MSRP</strong>
    </Fragment>
  ) : (
    ` - ${getPriceString(msrp)} MSRP`
  );
  return msrp ? msrpText : '';
};

export const getTrimsWithLabels = trims => {
  const mostPopularStyleId = get(maxBy(trims, 'shareBySalesSum'), 'styleId');

  return trims
    .map(trim => ({
      ...trim,
      label: `${trim.trimName}${trim.styleId === mostPopularStyleId ? ` (Most Popular)` : ''}${getPricingLabel(trim)}`,
      displayLabel: (
        <Fragment>
          {trim.trimName}
          {trim.styleId === mostPopularStyleId ? ` (Most Popular)` : ''}
          {getPricingLabel(trim, true)}
        </Fragment>
      ),
    }))
    .sort((a, b) => get(a, 'pricing.baseMsrp', 0) - get(b, 'pricing.baseMsrp', 0));
};

export function getTrimValue(trim) {
  const name = trim.trimName || trim.name || trim.trim;
  return name
    .replace('(Most Popular)', '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/\./g, '')
    .toLowerCase();
}
