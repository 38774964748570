import React, { Fragment, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { isEmpty, uniqBy } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildMakeModelDefaultYear,
  buildMmyModelFamilyIdsPath,
  buildModelFamilyIdsModelYearsPath,
  VehicleModel,
  VehicleEntities,
  buildForSaleYearsPath,
} from 'client/data/models/vehicle-v2';
import {
  getFilteredModelYears,
  getModelYearsWithReview,
  getModelYearsForSale,
  getForSaleLinks,
} from 'site-modules/shared/utils/core-page/model-year-helpers';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';
import { YearLink } from 'site-modules/shared/components/core-page/year-link/year-link';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';

import './other-years.scss';

const COLLAPSE_ID = 'other-years-collapse';
const YEAR_LIST_STYLE = 'years-list-grid list-unstyled m-0';
const COLLAPSE_BTN_GRID_SIZE = 0.7;

export function OtherYearsUI({
  vehicle,
  modelYears,
  forSaleYears,
  defaultYear,
  heading,
  headingClassName,
  renderEmptyHidden,
  isMobile,
  hasSpeculationRule,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  function toggle() {
    setIsOpen(!isOpen);
  }

  const {
    year,
    model: { name: modelName, slug: modelSlug },
    make: { name: makeName, slug: makeSlug },
  } = vehicle;
  const filteredModelYears = useMemo(() => getFilteredModelYears(modelYears, modelName), [modelYears, modelName]);

  if (isEmpty(filteredModelYears)) {
    return null;
  }

  const allModelYears = getModelYearsWithReview(filteredModelYears, defaultYear);
  const yearsForSale = uniqBy(
    [...getForSaleLinks({ forSaleYears, makeSlug, modelSlug }), ...getModelYearsForSale(filteredModelYears)],
    'name'
  );

  const yearsPerLine = isMobile ? 4 : 7;

  const headerFirstLinks = allModelYears.slice(0, yearsPerLine);
  const collapsedFirstLinks = allModelYears.slice(yearsPerLine);
  const hasCollapse = allModelYears.length > yearsPerLine || !!yearsForSale.length;
  if (!renderEmptyHidden && allModelYears.length < 2) {
    return null;
  }

  return (
    <div
      className={classnames('years-lite-pills', { 'd-none': renderEmptyHidden && allModelYears.length < 2 })}
      data-tracking-parent="years-module"
      ref={containerRef}
    >
      {!!heading && <div className={headingClassName}>{heading}</div>}
      <div className="medium">
        <div
          className="collapse-header"
          style={{
            display: 'grid',
            gridTemplateColumns: `${headerFirstLinks.length}fr ${yearsPerLine -
              headerFirstLinks.length +
              COLLAPSE_BTN_GRID_SIZE}fr `,
          }}
        >
          <ul
            className="years-list-header list-unstyled"
            style={{
              margin: '-5px',
              padding: '5px',
              display: 'grid',
              gridTemplateColumns: `repeat(${headerFirstLinks.length}, 1fr)`,
            }}
          >
            {headerFirstLinks.map(({ name, url, isUsed, noFollow, noSpeculation, trackingId }) => (
              <YearLink
                key={`year-${name}`}
                year={name}
                url={url}
                currentYear={year}
                skipCurrentYear
                trackingId={trackingId}
                noFollow={noFollow || isUsed}
                noSpeculation={noSpeculation}
              />
            ))}
          </ul>
          {hasCollapse && (
            <div className={classnames('d-flex', { 'justify-content-end': headerFirstLinks.length === yearsPerLine })}>
              <Button
                size="sm"
                color="link"
                onClick={toggle}
                className="collapse-year-btn small text-transform-none p-0"
                style={{ fontSize: '.75rem', textTransform: 'none', minWidth: '3rem', fontWeight: 'normal' }}
                data-tracking-id="see_other_years"
                aria-label={`${isOpen ? 'Less' : 'More'} ${makeName} ${modelName} model years`}
                aria-controls={COLLAPSE_ID}
                aria-expanded={isOpen}
              >
                {isOpen ? 'less' : 'more'}{' '}
                <i className={classnames('icon size-9', { 'icon-arrow-down3': !isOpen, 'icon-arrow-up3': isOpen })} />
              </Button>
            </div>
          )}
        </div>
        {hasCollapse && (
          <AnimatedCollapse isOpen={isOpen} className="overflow-hidden-focusable" id={COLLAPSE_ID}>
            {!!collapsedFirstLinks.length && (
              <div className="review-years wrapper-collapse-years">
                <ul className={classnames(YEAR_LIST_STYLE, 'mt-1_5')}>
                  {collapsedFirstLinks.map(({ name, url, isUsed, noFollow, noSpeculation, trackingId }) => (
                    <YearLink
                      key={`year-${name}`}
                      year={name}
                      url={url}
                      currentYear={year}
                      skipCurrentYear
                      trackingId={trackingId}
                      noFollow={noFollow || isUsed}
                      noSpeculation={noSpeculation}
                    />
                  ))}
                </ul>
              </div>
            )}
            {!!yearsForSale.length && (
              <Fragment>
                <div className="mt-1_5 mb-1 heading-6">
                  {makeName} {modelName} for Sale
                </div>
                <div className="wrapper-collapse-years">
                  <ul className={YEAR_LIST_STYLE}>
                    {yearsForSale.map(({ name, url, noFollow, trackingId }) => (
                      <YearLink
                        key={`year-${name}`}
                        year={name}
                        url={url}
                        trackingId={trackingId}
                        noFollow={noFollow}
                      />
                    ))}
                  </ul>
                </div>
              </Fragment>
            )}
          </AnimatedCollapse>
        )}
      </div>

      {hasSpeculationRule && (
        <Fragment>
          <SpeculationRule
            observeRef={containerRef}
            selector=".years-lite-pills .years-list-header a"
            notSelector="[rel~=nofollow]"
          />
          <SpeculationRule
            observeRef={containerRef}
            action="prefetch"
            eagerness="moderate"
            selector=".years-lite-pills .years-list-header a[rel~=nofollow], .years-lite-pills .wrapper-collapse-years.review-years a"
          />
        </Fragment>
      )}
    </div>
  );
}

OtherYearsUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear,
  modelYears: PropTypes.arrayOf(PropTypes.shape({})),
  forSaleYears: PropTypes.arrayOf(PropTypes.number),
  defaultYear: PropTypes.number,
  heading: PropTypes.string,
  headingClassName: PropTypes.string,
  renderEmptyHidden: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasSpeculationRule: PropTypes.bool,
};

OtherYearsUI.defaultProps = {
  vehicle: null,
  modelYears: null,
  forSaleYears: null,
  defaultYear: null,
  heading: null,
  headingClassName: 'heading-5 mb-0_75',
  renderEmptyHidden: false,
  isMobile: false,
  hasSpeculationRule: false,
};

const OtherYearsWrapper = connectToModel(OtherYearsUI, {
  defaultYear: bindToPath(
    ({ vehicle }) => buildMakeModelDefaultYear({ make: vehicle.make.slug, model: vehicle.model.slug }),
    VehicleModel
  ),
  modelYears: bindToPath(
    ({ modelFamilyIds }) => !isEmpty(modelFamilyIds) && buildModelFamilyIdsModelYearsPath(modelFamilyIds),
    VehicleModel
  ),
});

export const OtherYears = connectToModel(OtherYearsWrapper, {
  modelFamilyIds: bindToPath(
    ({ vehicle }) =>
      buildMmyModelFamilyIdsPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
    VehicleModel
  ),
  forSaleYears: bindToPath(
    ({ vehicle }) => buildForSaleYearsPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
    VehicleModel
  ),
});
